@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
body,
button {
    font-family: "Inter", sans-serif;
}

:root {
    --offcanvas-width: 270px;
    --topNavbarHeight: 0px;
}

.sidebar-nav {
    width: var(--offcanvas-width);
}

.sidebar-link {
    display: flex;
    align-items: center;
}

.sidebar-link .right-icon {
    display: inline-flex;
}

.sidebar-link[aria-expanded="true"] .right-icon {
    transform: rotate(180deg);
}

@media (min-width: 992px) {
    body {
        overflow: auto !important;
    }
    main {
        margin-left: var(--offcanvas-width);
    }
    /* this is to remove the backdrop */
    .offcanvas-backdrop::before {
        display: none;
    }
    .sidebar-nav {
        -webkit-transform: none;
        transform: none;
        visibility: visible !important;
        height: calc(100% - var(--topNavbarHeight));
        top: var(--topNavbarHeight);
    }
}