.pureconnect-login-svg {
    width: 35%;
    height: auto;
    display: block;
    margin: auto;
}

.pureconnect-login-header {
    width: 50%;
    text-align: center;
    margin: auto;
    display: block;
    color: #4e5055;
}

.pureconnect-login-input-group {
    width: 35%;
    margin: auto;
}

.pureconnect-login-form-control {
    border: 1px solid #4e5054;
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.pureconnect-login-vert-center {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    margin-top:-2rem !important;
}

.pureconnect-login-container {
    background-color: #d3d3d4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.pureconnect-login-alert {
    margin: auto;
    margin-top: 1%;
    display: block;
    width: 35%;
}