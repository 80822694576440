.brand-logo {
    height: 13%;
    width: 18%;
    margin: 11px 0px -2px 13px
}

body {
    font-size: 0.9rem !important;
    background-color: #ffffff !important;
}

.iconclr {
    color: #00a89b !important;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #00a89b;
}

.toolbar-padding
{
    padding-left: 12% !important;
}
.nav-link {
    color: #0c1014 !important;
}

.offcanvas-start {
    border-right: 1px solid rgb(0 0 0 / 58%);
}

.btn-custom {
    padding: 3px 20px 3px 20px !important;
    margin-right: 7px !important;
    line-height: 2.5 !important;
    color: #00a89b !important;
    background-color: #fff !important;
    border-color: #00a89b !important;
      
}

.custom-fluid {
    margin-top: -45px;
}

.input-group-append {
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.multi-select-width{
    width: 343px;
}


.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.filter-btn {
    margin-right: 10.5rem;
}

.custom-head {
    background-color: #fff;
    color: #00a89b;
}

#changeYear {
    margin-left: 30px;
    padding: 8px;
    width: 60%;
}

.line-custom {
    margin-top: 1.rem!important;
    margin-bottom: 0.5rem!important;
}

.top-bar {
    margin-top: 5px !important;
}
.customer-name{
    font-weight: 700 !important;
    margin-left: 5px;
}

.modal-label{
    margin-top: 15px;
}

.modal-filters{
    margin-left: 0px;
    margin-top: 10px;
}
.modal-filter-padding{
    margin-left: 0px;
    padding-top: 10px;
}

.vizContainer{
    height: 90vh;
}

.sidebar-toggle{
    --offcanvas-width : 30px;
    --webkit-transition : 3s;

}